<template>
  <v-container>
    <v-container class="text-start my-10">
      <kurcc-brand :icon-size="70" class="float-left text-center" icon-color="black" text-color-class="text--black"/>
      <span class="text-start">
        {{ $vuetify.lang.t('$vuetify.pages.about.firstSection') }}
      </span>
    </v-container>
    <v-container>
      <v-img src="https://via.placeholder.com/900x500"/>
    </v-container>
    <v-container class="text-start my-10">
      <span class="text-start">
        {{ $vuetify.lang.t('$vuetify.pages.about.secondSection') }}
      </span>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'KurccAboutBody',
  components: {
    KurccBrand: () => import('@/modules/app/components/KurccBrand')
  }
}
</script>
